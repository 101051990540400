import Container from "../styledComponents/Container";
import Nav from "./Nav";

function Contact() {
    return (
        <>
            {/* <Nav />         */}
            <Container>
                <h1>Contact</h1>
            </Container>
        </>
    );
}

export default Contact;