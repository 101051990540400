import styled from "styled-components";

export default styled.div`

    overflow-x: scroll;
    position:absolute;
    top:50%;
    left:0;
    width:100vw;
    translate:0 -50%;
    height:100vh;
    /* scroll-snap-type: x proximity; */
    display:flex;
    align-items:center;
    gap:10rem;

    & > div {
        scroll-snap-align: center;
        scroll-margin: 20px;
    }

    & > div:first-child {
        margin-left:calc(50% - 29.5rem); //half scroll element width + padding
    }
    & > div:last-child {
        margin-right:calc(50% - 29.5rem);
    }
    /* padding:0 50% */
`
