import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import About from './components/About';
import Contact from './components/Contact';
import { HelmetProvider } from 'react-helmet-async';
import Journey from './components/Journey';
import Nav from './components/Nav';
import LocaleContextProvider from './hooks/LocaleContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LocaleContextProvider>

      <HelmetProvider>

        <Router>
          <Routes>
            <Route path="/" exact element={<App />}/>
            <Route path="/:journeySlug" element={<Journey />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Nav />
        </Router>

      </HelmetProvider>
    </LocaleContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
