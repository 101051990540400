import { useContext, useEffect, useLayoutEffect,useState, useRef } from "react";
import styled from "styled-components";
import { LocaleContext } from "../hooks/LocaleContext";



const StyledScroller = styled.div`
    all:unset;
    display:block ;
    position:absolute ;
    left: 0;
    bottom: 0;
    text-align:center;
    width:33.3333vw;
    color:white;
    background-color: black;
    font-size:2rem ;
    height:6rem;
    /* border-radius:4rem ; */
    border-top-left-radius:4rem;
    border-top-right-radius:4rem;
    /* border-bottom-left-radius: 4rem; */
    
    #slider {
        width:80%;
        height:100%;
        position:relative;
        left:10%;
        
        #rail {
            width:100%;
            height:2px;
            position:absolute;
            top:calc(50% - 1.5rem);
            left:0%;
            /* background-color:grey; */
            background: rgb(37,37,37);
            background: linear-gradient(90deg, rgba(37,37,37,1) 0%, rgba(207,207,207,1) 50%, rgba(207,207,207,1) 100%);
        }
        #handle {
            cursor:pointer;
            width:3.5rem;
            aspect-ratio:1;
            /* height:3rem; */
            background:rgb(255,255,255);
            position:absolute;
            top:calc(50% - 1.5rem);
            border-radius:50%;
            translate: -50% ;
        }
    }

`
function Scroller({scrollThis,scrollTo}) {

    const {setscroll,scroll} = useContext(LocaleContext)
    const [scrollPercentage, setscrollPercentage] = useState(0);
    const [hasScrolled, sethasScrolled] = useState(false);

    const sliderContainerRef = useRef()

    useEffect(() => {
        console.dir(scrollTo / scrollThis.current.scrollWidth);
        
        // scrollThis.current.scrollTo({
        //     left:600

        // })

        // if(scroll ) {
            // console.log("ADDED EVETN");
            scrollThis.current.addEventListener("scroll", handleScroll,true)
        // }
        return () => {
            sethasScrolled(false)
        }
 
    }, []);

    // useEffect(() => {
    //     setscrollPercentage((scrollTo / scrollThis.current.scrollWidth)*100)
        
    // }, [scrollTo]);

    useLayoutEffect(() => {
        // if(scroll > -1) {
            // console.log("SCROLLTo", scrollTo, scrollThis);
            ScrollElement(scroll,scrollThis.current,1)
        // }
    }, []);

    const ScrollElement = (val, el,delay) => {
        setTimeout(() => {
            el.scrollTo({
                left:val
            })
            
          
        }, delay);
        setTimeout(() => {
            sethasScrolled(true)
            
        }, delay+10);
    }

    const handleScroll = (e) => {
        // console.dir(e.target.scrollLeft / scrollThis.current.scrollWidth);
        // console.log(e.target.scrollLeft, scrollThis.current.scrollWidth);
        setscroll(e.target.scrollLeft)
        // console.dir(e);
        setscrollPercentage((e.target.scrollLeft / (scrollThis.current.scrollWidth - window.innerWidth))*100)

    }
    let screenXStart = null

    const handleMouseDown = (e) => {
        e.preventDefault()
        screenXStart = e.screenX
        window.addEventListener("mousemove", handleMouseMove)
        window.addEventListener("mouseup", handleMouseUp)
    }
    const handleMouseUp = (e) => {
        window.removeEventListener("mousemove", handleMouseMove)
    }

    const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

    const handleMouseMove = (e) => {
        const {clientWidth} = sliderContainerRef.current
        
        const percentageOffset = ((e.screenX - screenXStart)/clientWidth)*100
        const newPercentage = clamp(scrollPercentage + percentageOffset,0,100)
        
        console.log(newPercentage);
        setscrollPercentage(newPercentage)

        const scrollValue = (scrollThis.current.scrollWidth - window.innerWidth) * ((newPercentage)/100)
        ScrollElement(scrollValue,scrollThis.current,0)
    }
    return (
        <StyledScroller >
            <div id="slider" ref={sliderContainerRef}>
                <div id="rail"></div>
                <div id="handle" onMouseDown={handleMouseDown} style={{left:`${scrollPercentage}%`,display:hasScrolled ? "block" : "none"}}></div>
            </div>
        </StyledScroller>
    );
}

export default Scroller;