import { useNavigate } from 'react-router-dom'
import NavExpander from './NavExpander';
import NavButton from '../styledComponents/NavButton';
import { useContext, useEffect, useState } from 'react';
import { LocaleContext } from '../hooks/LocaleContext';
import ExpanderLink from '../styledComponents/ExpanderLink';
import { ReactComponent as Logo } from '../styledComponents/0eA.svg';


function NavItem({obj}) {

    const navigate = useNavigate()
    const {JourneyTitle,Slug} = obj
    return (
        <ExpanderLink>
            <button onClick={()=> navigate(`/${encodeURIComponent(Slug)}`)}>{JourneyTitle}</button>
        </ExpanderLink>
    )
}

function Nav() {

    const navigate = useNavigate()

    const [journeyLinks, setjourneyLinks] = useState();
    // const [collapse,setcollapse] = useState(0)

    // const {data, loading, error} = useGetJourneys(LocaleContext)
    const {journeys, setcollapse,collapse} = useContext(LocaleContext)

    useEffect(() => {
        if(journeys) {
            const journeyItems = journeys.map(j => <NavItem key={j.id} obj={j}/>)
            setjourneyLinks(journeyItems)
        }
    }, [journeys]);

    const handleNavigate = (dest) => {
        navigate(dest)
        setcollapse(collapse+1)
    }


    return (
    <>
        <nav>
            <NavButton onClick={() => handleNavigate("/")} pos={{pos:"top",left:0}}><span><Logo /></span></NavButton>
            
            <NavExpander name={"journeys"} pos={{pos:"top",left:1}} collapse={collapse}>
                {journeyLinks}
            </NavExpander>
            
            <NavExpander name={"zines"} pos={{pos:"top",left:2}} collapse={collapse}>

            </NavExpander>

        </nav>

        <nav>

            <NavButton onClick={()=>handleNavigate("/about")} pos={{pos:"bottom",left:1}}>
                about
            </NavButton>

            <NavButton onClick={()=>handleNavigate("/contact")} pos={{pos:"bottom",left:2}}>
                contact
            </NavButton>

        </nav>

    </>
    );
}

export default Nav;