import React, {useEffect,useState} from 'react';

export const LocaleContext = React.createContext()

function LocaleContextProvider(props) {

    const BASE_URL = process.env.REACT_APP_REST_API_URL

    const [locale, setlocale] = useState("en");
    const [data, setdata] = useState(null);
    const [loading, setloading] = useState(true);
    const [error, seterror] = useState("");
    const [scroll, setscroll] = useState(1);
    const [collapse, setcollapse] = useState(0);
    const [offsetFirstArticle,setOffsetFirstArticle] = useState(0)

    const [journeys, setjourneys] = useState([]);
    const [zines,setzines] = useState([]);

    useEffect(() => {
        if(locale) {
            const journeysURL = `${BASE_URL}/journeys?_locale=${locale}`
            fetch(journeysURL)
                .then((res) => res.json())
                .then((data) => {
                    // seterror(data.error)
                    // setdata(data.joke)
                    setloading(false)
                    // const activeData = data.filter(j => j.active)
                    setjourneys(data.filter(j => j.Active));
                    // setjourneys(data)
            })
            const articlesURL =  `${BASE_URL}/writings?_locale=${locale}` //NEED TO SET PERMISSIONS IN STARPI TO GET
        }
    }, [locale]);

    useEffect(() => {
        console.log(journeys);
    }, [journeys]);

    return (
        <LocaleContext.Provider 
            value={{
                locale:locale,
                setlocale:setlocale,
                journeys:journeys,
                zines:zines,
                loading:loading,
                scroll:scroll,
                setscroll:setscroll,
                collapse:collapse,
                setcollapse:setcollapse,
                offsetFirstArticle:offsetFirstArticle,
                setOffsetFirstArticle:setOffsetFirstArticle
            }}
        >
            {props.children}
        </LocaleContext.Provider>
    )
}


export default LocaleContextProvider