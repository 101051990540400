import styled from "styled-components";

export default styled.div`

    padding:2rem;
    height:30rem;
    width:80%;
    flex-shrink:0;
    border:1px solid white;
    button {
        all:unset;
        color:white;
        text-decoration:none;
        font-size:1.6rem;
        cursor:pointer;
    }

`
