import './App.css';
import { useContext, useEffect, useState, useRef, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { LocaleContext } from './hooks/LocaleContext';
import SideScrollerContainer from './styledComponents/SideScrollerContainer';
import MainArticle from './components/MainArticleComp';
import Scroller from './components/Scroller';
function App() {

  const {locale, journeys, zines, scroll, setscroll} = useContext(LocaleContext)

  const [previews, setpreviews] = useState();

  const scrollerRef = useRef()
  useEffect(() => {

    const combinedArr = [...journeys,...zines]

    if(combinedArr.length > 0) {
      const sortedArr = combinedArr.sort(compareDate)
      // console.log(sortedArr);
      setpreviews(sortedArr.map((el,index) => <MainArticle key={el.id} index={index} data={el} kind={el.JourneyTitle ? "journey" : "zine"}/>))

    }

  }, [journeys,zines]);

  // useLayoutEffect(() => {
  //   console.log("SCROLL", scroll);
  //   scrollerRef.current.addEventListener("scroll", handleScroll)
  //   if(scroll) {
  //     ScrollElement(scroll,scrollerRef.current, 1)
  //   }
  // },[]);

  // const handleScroll = (e) => {
  //   console.dir(e.target.scrollLeft);
  //   setscroll(e.target.scrollLeft)

  // }

  // const ScrollElement = (val, el, delay) => {
  //   console.log("SCROLL FUNCTION", val);
  //   setTimeout(() => {
  //       el.scrollTo({
  //           left:val
  //       })
      
  //   }, delay);
  // }


  const compareDate = (a,b) => {
    const dateA = new Date(a.published_at).getTime()
    const dateB = new Date(b.published_at).getTime()
    // console.log(dateA);
    return dateA - dateB

  }





  return (
    <>
      <Helmet>
        <title>Third Research Lab - Home</title>
        <meta name="description" content="Helmet application" />
        <html lang={`${locale}`} amp />
        <meta property="og:type" content="article" />
      </Helmet>


        
        <SideScrollerContainer ref={scrollerRef}>
          {previews}
        </SideScrollerContainer>

      <Scroller scrollThis={scrollerRef} scrollTo={scroll}/>
    </>
  );
}

export default App;
