// import './App.css';
import { useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react';
import Nav from '../components/Nav';
import { Helmet } from 'react-helmet-async';
import Container from '../styledComponents/Container';
import { LocaleContext } from '../hooks/LocaleContext';
import styled from 'styled-components';

const JourneyBox = styled.div`

`

function Journey() {
  const { journeySlug } = useParams()
  const [currentJourney, setCurrentJourney] = useState();

  const {journeys} = useContext(LocaleContext)



  useEffect(() => {
    if(journeys.length > 0 && journeySlug) {
        console.log("FETCHED",journeySlug,journeys);
        const journeyArr = journeys.filter(j => j.Slug === journeySlug)
        const journey = journeyArr[0]
        console.log(journey);
        const comp = 
        <JourneyBox>
            <h1>{journey.JourneyTitle}</h1>
            <article>
                {journey.JourneyText}
            </article>
        </JourneyBox>
        setCurrentJourney(comp)
    }
  }, [journeys, journeySlug]);

  return (
    <>
      <Helmet>
        <title>Third Research Lab - Home</title>
        <meta name="description" content="Helmet application" />
        <html lang="en" amp />
        <meta property="og:type" content="article" />
      </Helmet>

      <Nav />

      <Container>
        {currentJourney}

      </Container>

    </>
  );
}

export default Journey;
