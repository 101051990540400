import Container from "../styledComponents/Container";

function About() {
    return (
        <>
            <Container>
                <h1>ABOUT</h1>
            </Container>
        </>
    );
}

export default About;