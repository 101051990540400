import { useState, useEffect } from 'react';
import styled from 'styled-components';
import NavButton from '../styledComponents/NavButton';

const Expander = styled.div`
    position: absolute;
    width:33.3333vw;
    background-color:black ;
    height:${props => props.isExpanded ? "50vh" : "6rem"};
    left: ${props => `${props.pos.left*33.333}vw`};
    ${props => `border-${props.pos.pos === "top" ? "bottom" : "top"}-left-radius:4rem;`}
    ${props => `border-${props.pos.pos === "top" ? "bottom" : "top"}-right-radius:4rem;`}
    ${props => `${props.pos.pos}:0`};
    transition:height 0.2s ease-in-out ;
    #list {
        display:flex;
        align-items:center;
        gap:20px;
        flex-direction: column;
        height:calc(100% - 6rem) ;
        overflow-y:${props => props.isExpanded ? "scroll" : "hidden"};
        color:red;
        margin:6rem 0.5rem;

        & > div:first-child {
        margin-top:5rem;
    }
    & > div:last-child {
        margin-bottom:5rem;
    }
    }


`

function NavExpander({name,children,pos, collapse}) {

    const [isExpanded, setisExpanded] = useState(false);

    useEffect(() => {
        if(collapse > 0) {
            setisExpanded(false)
        }
    }, [collapse]);
    return (
        <>
        <Expander  isExpanded={isExpanded} pos={pos}>
            <div id="list" onClick={() => setisExpanded(false)}>
                {children}

            </div>
        </Expander>
        <NavButton onClick={()=>setisExpanded(!isExpanded)} pos={pos}>
            <span>{name}</span>
        </NavButton>
        </>
    );
}

export default NavExpander;