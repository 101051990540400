import styled from "styled-components";

const NavButton = styled.button`
    all:unset;
    position:absolute ;
    left: ${props => `${props.pos.left*33.333}vw`};
    ${props => `${props.pos.pos}:0`};
    text-align:center;
    width:33.3333vw;
    color:white;
    background-color: black;
    font-size:2rem ;
    height:6rem;
    /* border-radius:4rem ; */
    ${props => `border-${props.pos.pos === "top" ? "bottom" : "top"}-left-radius:4rem;`}
    ${props => `border-${props.pos.pos === "top" ? "bottom" : "top"}-right-radius:4rem;`}
    /* border-bottom-left-radius: 4rem; */
    cursor:pointer;

    &:active {
        color:black;
        background-color:white ;
        svg {
            fill:black;
            path {
                
                fill:black;
            }
        }
    }
    svg {
        height:60%;
        color:white;
        fill:white;
        path {
            
            fill:white;
        }
    }

    
`

export default NavButton