import styled from "styled-components";

const Container = styled.div`

    position:absolute;
    margin:6rem 1rem;
    height:calc(100vh - 12rem);
    width:calc(100vw - 2rem);
`


export default Container