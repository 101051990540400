import { useContext, useRef, useEffect, useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LocaleContext } from "../hooks/LocaleContext";

const ScrollElement = styled.div`
    width:55rem;
    /* min-width:calc(33vw - 4rem); */
    font-size:2rem;
    flex-shrink:0;
    /* height:calc(50vh - 4rem); */
    background-color:black;
    color:white;
    border-radius:2rem;
    padding:2rem;
    box-shadow:rgba(0, 0, 0, 0.9) 0px 0px 50px 30px;
    /* border:1px solid black; */
    transform-origin: center top;
    cursor:pointer;

    transition:box-shadow 0.1s linear;
    &:hover {
        box-shadow:rgba(0, 0, 0, 0.9) 0px 0px 60px 36px;
    }
`

function MainArticle({kind,data, index}) {

    const {setcollapse, collapse, scroll,offsetFirstArticle, setOffsetFirstArticle} = useContext(LocaleContext)

    

    const navigate = useNavigate()
    const boxRef = useRef()
    const [percentFromMiddle, setpercentFromMiddle] = useState()
    const [fullWidth, setFullWidth] = useState()

    const {JourneyTitle, Slug} = data

    const handleNavigate = (dest) => {
        navigate(`/${encodeURIComponent(Slug)}`)
        setcollapse(collapse+1)
    }

    useLayoutEffect(() => {
        const newFullWidth = (window.innerWidth / 2) + boxRef.current.clientWidth / 2
        setFullWidth(newFullWidth)
        const localOffset = boxRef.current.offsetLeft - offsetFirstArticle
        const relativeOffset = Math.abs(scroll - localOffset)
        const percent = 1 - (relativeOffset / newFullWidth)
        setpercentFromMiddle(percent)
        console.log(percentFromMiddle);
        if(index === 0) {
            setOffsetFirstArticle(boxRef.current.offsetLeft)
            window.addEventListener("resize",handleResize)
        }
        return () => {
            window.removeEventListener("resize",handleResize)
        }
    }, [boxRef]);

    useEffect(() => {
        const localOffset = boxRef.current.offsetLeft - offsetFirstArticle
        const relativeOffset = Math.abs(scroll - localOffset)
        const percent = 1 - (relativeOffset / fullWidth)
        setpercentFromMiddle(percent)
    }, [offsetFirstArticle, scroll]);

    const handleResize = (e) => {
        console.log(boxRef.current.offsetLeft);
        setOffsetFirstArticle(boxRef.current.offsetLeft)
        
    }

    return (
        <ScrollElement ref={boxRef} onClick={handleNavigate} style={{transform:`scale(${percentFromMiddle})`}}>
            <h1>{JourneyTitle}</h1>
            <div style={{"padding":"56.25% 0 0 0","position":"relative"}}>
                <iframe 
                    src="https://player.vimeo.com/video/664623427?h=7215b98227&title=0&byline=0&portrait=0" 
                    style={{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"}} 
                    frameborder="0" 
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
            {/* <p>{percentFromMiddle} : {fullWidth}</p> */}
        </ScrollElement>
    );
}

export default MainArticle;